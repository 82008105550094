// App.js
import React, { useEffect, useState } from 'react';
import { AuthProvider } from '@descope/react-sdk';
import LoginFormComponent from './LoginForm';
import AuthSuccess from './AuthSuccess';


function App() {
  const [loggedIn, setLoggedIn] = useState(false); 

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const version = queryParams.get('version');
    if (code) {
      setLoggedIn(true);
      let redirectUrl;
  
      if (version === 'VS_CODE') {
        redirectUrl = 'vscode://KeyRunner.keyrunner/auth/callback?version=desktop&code=' + code;
      } else if (version === 'DESKTOP') {
        redirectUrl = 'keyrunner://KeyRunner.keyrunner/auth/callback?version=desktop&code=' + code;
      } else {
        // Handle unknown version
        console.error('Unknown version:', version);
        return;
      }
  
      window.open(redirectUrl, '_self');
    }
  }, []);

  // useEffect(() => {
  //   const getCookie = (name) => {
  //     const value = `; ${document.cookie}`;
  //     const parts = value.split(`; ${name}=`);
  //     if (parts.length === 2) return parts.pop().split(';').shift();
  //   };

  //   const userEmail = getCookie('userEmail');
  //  const userEmailID = decodeURIComponent(userEmail);
  //  const version = getCookie('version');
   
  //     let redirectUrl;
  
  //     if (userEmailID && version === 'VS_CODE') {
  //       setLoggedIn(true);
  //       redirectUrl = 'vscode://KeyRunner.keyrunner/auth/callback?version=desktop&code=';
  //     } else if (userEmailID && version === 'DESKTOP') {
  //       setLoggedIn(true);
  //       redirectUrl = 'keyrunner://KeyRunner.keyrunner/auth/callback?version=desktop&code=';
  //     } else {
  //       // Handle unknown version
  //       console.error('Unknown version:', version);
  //       return;
  //     }
  
  //     window.open(redirectUrl, '_self');
    
  // }, []);

  return (
    <AuthProvider projectId="P2VC2ZaGsqCywqu8jAhwZpp8Umyf">
    {loggedIn ? <AuthSuccess/> : <LoginFormComponent setLoggedIn={setLoggedIn} />}
  </AuthProvider>
  );
}

export default App;
