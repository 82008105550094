import React, { useState,useEffect  } from 'react';
import { useDescope } from '@descope/react-sdk';
import { LoginForm } from './UIComponents';
import { useCookies } from 'react-cookie';


function LoginFormComponent({ setLoggedIn }) {
  const descopeSdk = useDescope();
  const [errorMessage, setErrorMessage] = useState(null);
  const [cookies, setCookie] = useCookies(['userEmail', 'version']);
  const [email, setEmail] = useState('');



  function getQueryParam(url, param) {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get(param);
  }

  const handleLoginClick = async () => {
    //const redirectURL = 'http://localhost:3000?callback=keyrunner://auth/callback&version=desktop';
    const providedURL = window.location.href;
    const redirectUri = getQueryParam(providedURL, 'redirect_uri');
    const version = getQueryParam(providedURL, 'version');
    if (!version) {
      console.error('Version parameter not found in URL');
      return;
    }
    const redirectURL = `https://identity.keyrunner.app/?callback=${encodeURIComponent(redirectUri)}&version=${encodeURIComponent(version)}`;
    const loginOptions = {
      stepup: false,
      mfa: false,
      customClaims: { claim: 'Value1' },
      templateOptions: { option: 'Value1' },
    };

    try {
      const resp = await descopeSdk.saml.start(email, redirectURL, loginOptions);
      //console.log(JSON.stringify(resp));
      if (!resp.ok) {
        const errorMessage = resp.data.errorMessage; 
        setErrorMessage(errorMessage);
      } else {
        const url = resp.data.url;
        window.location.href = url;
        const version = getQueryParam(providedURL, 'version');
        const userEmail = decodeURIComponent(email);

        setCookie('userEmail', userEmail, { path: '/', maxAge: 30 * 24 * 60 * 60 }); // Expires in 30 days
        setCookie('version', version, { path: '/', maxAge: 30 * 24 * 60 * 60 });
      }
    } catch (error) {
      setErrorMessage("An error occurred during SSO authentication. Please try again later.");
    }
  };
     return (
      <LoginForm email={email} setEmail={setEmail} handleLoginClick={handleLoginClick} errorMessage={errorMessage} />
    );
}

export default LoginFormComponent;
