import React, { useState, useEffect } from 'react';
import logo from './keyrunnerlogo.png';
import { useCookies } from 'react-cookie';


function AuthSuccess() {
  const [cookies, setCookie] = useCookies(['userEmail', 'version']);

  return (
    
    <div id="uiAfterLogin" style={{ display:'flex',background: 'linear-gradient(to bottom, #f2f3f5, #0057f5)', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ textAlign: 'center', maxWidth: '600px', padding: '40px', borderRadius: '10px', boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)', background: '#fff' }}>
        <div style={{ marginBottom: '20px' }}>
          <img src={logo} alt="Logo" style={{ width: '300px' }} />
        </div>
        <h1 style={{ fontSize: '24px', color: '#333' }}>You've successfully logged in to our API dashboard, {cookies.userEmail}</h1>
        <p style={{ fontSize: '18px', color: '#666', marginBottom: '20px' }}>Redirecting you to the  App</p>
      </div>
    </div>
  );
}

export default AuthSuccess;
