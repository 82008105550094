import React from 'react';
import { CssBaseline, Container, Grid, Typography, TextField, Button, Link, IconButton, Paper } from '@material-ui/core';
import { Facebook, Twitter, Instagram, LinkedIn, GitHub, Extension } from '@material-ui/icons';

import Logo from './keyrunnerlogo.png';

export function LoginForm({ email, setEmail, handleLoginClick, errorMessage }) {
  return (
    <>
      <CssBaseline />
      <div style={{ background: 'linear-gradient(to bottom, #f2f3f5, #0057f5)', minHeight: '100vh' }}>
        <Container maxWidth="lg">
          <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
            <Paper elevation={3} style={{ padding: 20, maxWidth: 400 }}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <img src={Logo} alt="Logo" style={{ maxWidth: '100%', marginBottom: 0 }} />
                </Grid>
                <Grid item>
                  <Typography variant="h6">SSO Email</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                {/* Error Message */}
                {errorMessage && (
                  <Grid item>
                    <Typography variant="body1" color="error" align="center" gutterBottom>
                      {errorMessage}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleLoginClick}
                    disabled={!email}
                  >
                    Login
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="body2" align="center">
                    By logging in, you agree to our <Link href="https://keyrunner.app/privacypolicy" target="_blank">Privacy Policy</Link>.
                  </Typography>
                </Grid>
                <Grid item container justify="center" spacing={2}>
                  <Grid item>
                    <IconButton href="https://marketplace.visualstudio.com/items?itemName=KeyRunner.keyrunner" target="_blank">
                      <Extension/>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton href="https://www.linkedin.com/company/96955951/" target="_blank">
                      <LinkedIn/>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton href="https://twitter.com/keyRunnerApp" target="_blank">
                      <Twitter />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton href="https://github.com/launchiamenterprise/keyrunner" target="_blank">
                      <GitHub />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </div>
    </>
  );
};
